import { clsxm } from 'lib';
import * as React from 'react';
import { NewsletterForm } from './NewsletterForm';

type NewsletterSectionProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLElement>,
  HTMLElement
>;

const NewsletterSection: React.FC<NewsletterSectionProps> = ({
  className,
  ...rest
}) => {
  const classes = clsxm('bg-green-700 py-12 px-4 !mt-0', className);
  return (
    <section className={classes} id='newsletter' {...rest}>
      <div className='container mx-auto max-w-7xl text-xl text-teal-500'>
        <NewsletterForm
          headline='Get Notified'
          subHeading="Sign up for our newsletter to stay up to date on next year's World Fair Trade day event and other great CFT news."
        />
      </div>
    </section>
  );
};

export { NewsletterSection };
