import { Title } from 'components/Title';
import { LatLngExpression } from 'leaflet';
import { FILTERS } from 'lib/constants';
import { Location } from 'lib/locations';
import { useSearch } from 'lib/useSearch';
import dynamic from 'next/dynamic';
import * as React from 'react';
import { LocationList } from './List';

const Map = dynamic(() => import('./Map'), { ssr: false });

const Locations: React.FC = () => {
  const [locations, setLocations] = React.useState<Location[]>([]);
  const filters = FILTERS;
  const { data, status, setQueryValue } = useSearch<Location>({
    filters,
  });

  React.useEffect(() => {
    if (data) {
      setLocations(data);
    }
  }, [data]);

  const [mapCenter, setMapCenter] = React.useState<LatLngExpression>([
    -87.716154, 41.926559,
  ]);

  React.useEffect(() => {
    if (locations && locations.length >= 1) {
      setMapCenter(locations[0].coordinates);
    }
  }, [locations]);

  return (
    <section className='container mx-auto max-w-7xl' id='locations'>
      <Title className='mb-12'>Destinations</Title>

      <div className='rounded bg-white'>
        <div className='flex h-64 w-full items-center justify-center rounded bg-stone-100 text-4xl lg:h-96'>
          <Map
            locations={locations}
            zoomControl
            center={mapCenter}
            status={status}
          />
        </div>
        {/* <div className='grid flex-wrap items-center gap-4 px-4 py-4 sm:flex sm:justify-end sm:px-6 md:flex-row md:flex-nowrap'>
          <Filters filters={filters} onChange={setQueryValue} />
        </div> */}
        <LocationList locations={locations} status={status} />
      </div>
    </section>
  );
};

export { Locations };
