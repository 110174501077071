import { faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clsxm } from 'lib';
import { TAGS } from 'lib/constants';
import { Location } from 'lib/locations';
import { UnstyledLink as Link } from 'components/links';
import * as React from 'react';
import { ClassValue } from 'clsx';
import { locationTag } from './helpers';

interface LocationListProps {
  locations?: Location[];
  status: 'idle' | 'error' | 'loading' | 'success';
}

type LocationListItemProps = Pick<
  Location,
  'category' | 'tags' | 'name' | 'address' | 'url' | 'tags' | 'coordinates'
> & {
  as?: typeof React.Component | string;
  className?: ClassValue;
};

export const LocationListItem: React.FC<LocationListItemProps> = ({
  name,
  url,
  address,
  category,
  tags,
  coordinates,
  className,
  as: Tag = 'li',
}) => {
  const icon = locationTag[category];
  const tagTheme = TAGS[category]?.color ?? 'stone';
  return (
    <Tag className={clsxm('location-list-item group', className)}>
      <Link href={url!} className='flex w-full gap-4'>
        <div
          className={clsxm(
            'stamp-effect flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full',
            `bg-${tagTheme}-500`
          )}
        >
          <FontAwesomeIcon
            icon={icon?.icon || faLocationDot}
            className='inline-block h-6 w-6 text-white'
          />
        </div>
        <div className='flex-grow'>
          <h3 className='mb-1 text-xl leading-tight group-hover:underline'>
            {name}
          </h3>
          <p className='mt-1 flex items-center text-xs text-stone-600'>
            {address}
          </p>
          <p className='mt-1 flex flex-wrap gap-2 text-xs'>
            {[category, ...tags].map((tag) => (
              <span
                key={tag}
                className={clsxm(
                  'rounded-full px-2 py-0.5 text-xs',
                  TAGS[tag]?.color
                    ? `bg-${TAGS[tag].color}-100 text-${TAGS[tag].color}-600`
                    : 'bg-stone-100 text-stone-600'
                )}
              >
                {TAGS[tag]?.label ? TAGS[tag].label : tag}
              </span>
            ))}
          </p>
        </div>
      </Link>
    </Tag>
  );
};

const LocationList: React.FC<LocationListProps> = ({
  locations = [],
  status,
}) => {
  switch (status) {
    case 'loading':
      return (
        <div className='grid min-h-[100px] animate-pulse gap-4 p-4 text-stone-200 md:grid-cols-2 lg:grid-cols-3 lg:gap-8 lg:p-6'>
          <div className='flex gap-4'>
            <div className='h-12 w-12 flex-shrink-0 rounded-full bg-current' />
            <div className='flex-grow space-y-2'>
              <div className='h-[1em] w-2/3 rounded-full bg-current text-xl' />
              <div className='h-[1em] w-5/6 rounded-full bg-current text-sm' />
              <div className='h-[1em] w-1/2 items-center rounded-full bg-current text-xs' />
            </div>
          </div>
          <div className='flex gap-4'>
            <div className='h-12 w-12 flex-shrink-0 rounded-full bg-current' />
            <div className='flex-grow space-y-2'>
              <div className='h-[1em] w-2/3 rounded-full bg-current text-xl' />
              <div className='h-[1em] w-5/6 rounded-full bg-current text-sm' />
              <div className='h-[1em] w-1/2 items-center rounded-full bg-current text-xs' />
            </div>
          </div>
          <div className='flex gap-4'>
            <div className='h-12 w-12 flex-shrink-0 rounded-full bg-current' />
            <div className='flex-grow space-y-2'>
              <div className='h-[1em] w-2/3 rounded-full bg-current text-xl' />
              <div className='h-[1em] w-5/6 rounded-full bg-current text-sm' />
              <div className='h-[1em] w-1/2 items-center rounded-full bg-current text-xs' />
            </div>
          </div>
        </div>
      );
    case 'success':
      if (locations && locations.length === 0) {
        return (
          <div className='items-center justify-center p-4 text-center text-stone-600'>
            <p className='text-xl font-bold '>No results found</p>
          </div>
        );
      }
      return (
        <ul className='grid gap-6 p-4 md:grid-cols-2 lg:grid-cols-3 lg:gap-8 lg:p-6'>
          {locations.map((location) => (
            <LocationListItem
              url={`/location/${location.slug}`}
              tags={location.tags}
              category={location.category}
              name={location.name}
              address={location.address}
              key={location.slug}
              coordinates={location.coordinates}
            />
          ))}
        </ul>
      );
    case 'error':
    default:
      return (
        <div className='items-center justify-center p-4 text-center text-stone-600'>
          <p className='text-xl font-bold'>
            Uh oh! Something went wrong fetching the listings.
          </p>
          <p>We've been notified and will look into it.</p>
          <p>
            <button
              type='button'
              onClick={() => window.location.reload()}
              className='mr-1 text-green-700 hover:text-green-600'
            >
              Click here
            </button>
            to try reloading the page.
          </p>
        </div>
      );
  }
};

export { LocationList };
