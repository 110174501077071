import {
  faForkKnife,
  faShoppingBag,
  faStore,
  faLeaf,
  faHeart,
  faShop,
  faHand,
  faBuildingColumns,
} from '@fortawesome/pro-solid-svg-icons';

import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { TAGS } from 'lib/constants';

export const locationTag: Partial<
  Record<keyof typeof TAGS, { icon?: FontAwesomeIconProps['icon'] }>
> = {
  eats: { icon: faForkKnife },
  retail: { icon: faShoppingBag },
  market: { icon: faStore },
  eco: { icon: faLeaf },
  enterprise: { icon: faHeart },
  studio: { icon: faShop },
  workshop: { icon: faHand },
  museum: { icon: faBuildingColumns },
};
