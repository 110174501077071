import * as React from 'react';
import { Title } from 'components/Title';
import Image from 'next/image';

interface SponsorsProps {}

type BaseSupporter = {
  alt?: string;
  className?: string;
  height?: number;
  loading?: 'lazy' | 'eager';
  logo?: string;
  name: string;
  src?: string;
  width?: number;
};
type Supporter =
  | ({
      type: 'donor';
    } & BaseSupporter)
  | ({
      type: 'sponsor';
    } & BaseSupporter);

const SPONSORS_DONORS: Supporter[] = [
  /**
   * Sponsors:
   *
   * GOEX
   * Pierce Family Foundation
   * FT USA/FT Campaigns
   * Worldfinds
   * Metropolis
   * Oxfam
   * Marketplace: Handwork of India
   * Fair Trade America
   */
  {
    name: 'GOEX',
    type: 'sponsor',
  },
  {
    name: 'Pierce Family Foundation',
    type: 'sponsor',
  },
  {
    name: 'Fair Trade Campaigns',
    type: 'sponsor',
  },
  {
    name: 'Worldfinds',
    type: 'sponsor',
  },
  {
    name: 'Metropolis',
    type: 'sponsor',
  },
  {
    name: 'Oxfam',
    type: 'sponsor',
  },
  {
    name: 'Marketplace: Handwork of India',
    type: 'sponsor',
  },
  {
    name: 'Fair Trade America',
    type: 'sponsor',
  },
  /**
   * Donors:
   *
   * Bright Endeavors
   * Divine
   * Fair Anita
   * Florecal
   * GOEX
   * HHP Lift
   * Just Haiti
   * Kirk's Natural Brands
   * Lake Champlain
   * Maine Root
   * Matr Boomie
   * Once Again Nut Butter
   * Revolution Brewing
   * Takasa
   * Tony's Chocolonely's
   * Traditional Medicinals
   * Vosges
   * Wholesome Sweeteners
   * Worldfinds
   */
  {
    name: 'Bright Endeavors',
    type: 'donor',
  },
  {
    name: 'Divine',
    type: 'donor',
  },
  {
    name: 'Fair Anita',
    type: 'donor',
  },
  {
    name: 'Florecal',
    type: 'donor',
  },
  {
    name: 'GOEX',
    type: 'donor',
  },
  {
    name: 'HHP Lift',
    type: 'donor',
  },
  {
    name: 'Just Haiti',
    type: 'donor',
  },
  {
    name: "Kirk's Natural Brands",
    type: 'donor',
  },
  {
    name: 'Lake Champlain',
    type: 'donor',
  },
  {
    name: 'Maine Root',
    type: 'donor',
  },
  {
    name: 'Matr Boomie',
    type: 'donor',
  },
  {
    name: 'Once Again Nut Butter',
    type: 'donor',
  },
  {
    name: 'Revolution Brewing',
    type: 'donor',
  },
  {
    name: 'Takasa',
    type: 'donor',
  },
  {
    name: "Tony's Chocolonely's",
    type: 'donor',
  },
  {
    name: 'Traditional Medicinals',
    type: 'donor',
  },
  {
    name: 'Vosges',
    type: 'donor',
  },
  {
    name: 'Wholesome Sweeteners',
    type: 'donor',
  },
  {
    name: 'Worldfinds',
    type: 'donor',
  },
];

const defaultSupporterProps: Omit<Supporter, 'type' | 'name'> = {
  className: 'object-contain',
  loading: 'lazy',
  height: 400,
  width: 400,
};

const Sponsors: React.FC<SponsorsProps> = () => {
  return (
    <section className='container mx-auto max-w-7xl px-4 sm:px-6'>
      <Title className='mb-12'>Thanks to our Sponsors & Donors</Title>
      <div className='sponsor-row sponsor-row--large mx-auto mt-12 flex max-w-5xl select-none flex-wrap items-center justify-center gap-x-12 gap-y-8'>
        {/**
         * Sponsors:
         *
         * GOEX
         * Pierce Family Foundation
         * FT USA/FT Campaigns
         * Worldfinds
         * Metropolis
         * Oxfam
         * Marketplace: Handwork of India
         * Fair Trade America
         */}
        <Image
          {...defaultSupporterProps}
          src='/static/logos/sponsors/goex.svg'
          alt='GOEX'
        />
        <Image
          {...defaultSupporterProps}
          src='/static/logos/sponsors/pierce-family-foundation.jpg'
          alt='Pierce Family Foundation'
        />
        <Image
          {...defaultSupporterProps}
          src='/static/logos/sponsors/fair-trade-campaigns.svg'
          alt='Fair Trade Campaigns'
        />
        <Image
          {...defaultSupporterProps}
          src='/static/logos/sponsors/world-finds.svg'
          alt='Worldfinds'
        />
        <Image
          {...defaultSupporterProps}
          src='/static/logos/sponsors/metropolis.svg'
          alt='Metropolis'
        />
        <Image
          {...defaultSupporterProps}
          src='/static/logos/sponsors/oxfam.svg'
          alt='Oxfam'
        />
        <Image
          {...defaultSupporterProps}
          src='/static/logos/sponsors/marketplace-handwork-of-india.svg'
          alt='Marketplace: Handwork of India'
        />
        <Image
          {...defaultSupporterProps}
          src='/static/logos/sponsors/fair-trade-america.png'
          alt='Fair Trade America'
        />
      </div>

      <div className='sponsor-row sponsor-row--small mx-auto mt-12 flex max-w-5xl select-none flex-wrap items-center justify-center gap-x-12 gap-y-8 grayscale sm:mt-24'>
        {/**
         * Donors:
         *
         * Bright Endeavors
         * Divine
         * Fair Anita
         * Florecal
         * GOEX
         * HHP Lift
         * Just Haiti
         * Kirk's Natural Brands
         * Lake Champlain
         * Maine Root
         * Matr Boomie
         * Once Again Nut Butter
         * Revolution Brewing
         * Takasa
         * Tony's Chocolonely's
         * Traditional Medicinals
         * Vosges
         * Wholesome Sweeteners
         * Worldfinds
         */}
        <Image
          {...defaultSupporterProps}
          src='/static/logos/donors/bright-endeavors.png'
          alt='Bright Endeavors'
        />
        <Image
          {...defaultSupporterProps}
          src='/static/logos/donors/divine.svg'
          alt='Divine'
        />
        <Image
          {...defaultSupporterProps}
          src='/static/logos/donors/fair-anita.png'
          alt='Fair Anita'
        />
        <Image
          {...defaultSupporterProps}
          src='/static/logos/donors/florecal.png'
          alt='Florecal'
        />
        <Image
          {...defaultSupporterProps}
          src='/static/logos/donors/goex.svg'
          alt='GOEX'
        />
        <Image
          {...defaultSupporterProps}
          src='/static/logos/donors/hhplift.png'
          alt='HHPLift'
        />
        <Image
          {...defaultSupporterProps}
          src='/static/logos/donors/just-haiti.svg'
          alt='Just Haiti'
        />
        <Image
          {...defaultSupporterProps}
          src='/static/logos/donors/kirks.png'
          alt="Kirk's Natural Brands"
        />
        <Image
          {...defaultSupporterProps}
          src='/static/logos/donors/lake-champlain.svg'
          alt='Lake Champlain'
        />
        <Image
          {...defaultSupporterProps}
          src='/static/logos/donors/maine-root.svg'
          alt='Maine Root'
        />
        <Image
          {...defaultSupporterProps}
          src='/static/logos/donors/matr-boomie.svg'
          alt='Matr Boomie'
        />
        <Image
          {...defaultSupporterProps}
          src='/static/logos/donors/once-again-nut-butter.png'
          alt='Once Again Nut Butter'
        />
        <Image
          {...defaultSupporterProps}
          src='/static/logos/donors/revolution-brewing.png'
          alt='Revolution Brewing'
        />
        <Image
          {...defaultSupporterProps}
          src='/static/logos/donors/takasa.png'
          alt='Takasa'
        />
        <Image
          {...defaultSupporterProps}
          src='/static/logos/donors/tonys-chocolonely.png'
          alt="Tony's Chocolonely"
        />
        <Image
          {...defaultSupporterProps}
          src='/static/logos/donors/traditional-medicinals.svg'
          alt='Traditional Medicinals'
        />
        <Image
          {...defaultSupporterProps}
          src='/static/logos/donors/vosges.jpg'
          alt='Vosges'
        />
        <Image
          {...defaultSupporterProps}
          src='/static/logos/donors/wholesome.jpeg'
          alt='Wholesome Sweeteners'
        />
        <Image
          {...defaultSupporterProps}
          src='/static/logos/donors/world-finds.svg'
          alt='Worldfinds'
        />
      </div>
    </section>
  );
};

export { Sponsors };
