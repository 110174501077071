import { Location } from './locations';
import { SortOptions } from './types';
import { Filter } from './useSearch';

export const TAGS = {
  retail: {
    label: 'Brick & Mortar Retail',
    color: 'amber',
    key: 'retail',
  },
  eco: {
    label: 'Extra-Eco',
    color: 'lime',
    key: 'eco',
  },
  market: {
    label: 'Fair Trade Pop-up Market',
    color: 'blue',
    key: 'market',
  },
  eats: {
    label: 'Food & Drink',
    color: 'teal',
    key: 'eats',
  },
  workshop: {
    label: 'Hands-on Workshop',
    color: 'cyan',
    key: 'workshop',
  },
  enterprise: {
    label: 'Local Social Enterprise',
    color: 'red',
    key: 'enterprise',
  },
  studio: {
    label: 'Studio Tour',
    color: 'pink',
    key: 'studio',
  },
  faith: {
    label: 'Faith-based',
    color: 'sky',
    key: 'faith',
  },
  fashion: {
    label: 'Fashion',
    color: 'emerald',
    key: 'fashion',
  },
  women: {
    label: 'Women-Owned',
    color: 'purple',
    key: 'women',
  },
  north: {
    label: 'Northside',
    color: 'stone',
    key: 'north',
  },
  northwest: {
    label: 'Northwest Side',
    color: 'stone',
    key: 'northwest',
  },
  south: {
    label: 'Southside',
    color: 'stone',
    key: 'south',
  },
  west: {
    label: 'Westside',
    color: 'stone',
    key: 'west',
  },
  suburban: {
    label: 'Suburban',
    color: 'stone',
    key: 'suburban',
  },
  'bike-tour': {
    label: 'Bike Tour',
    color: 'green',
    key: 'bike-tour',
  },
  coffee: {
    label: 'Coffee',
    color: 'chocolate',
    key: 'coffee',
  },
  museum: {
    label: 'Museum',
    color: 'green',
    key: 'museum',
  },
};

export const SORT_OPTIONS: SortOptions<Location>[] = [
  { label: 'A-Z', value: 'name' },
  { label: 'Location', value: 'location' },
];

export const FILTERS: Filter<Location>[] = [
  {
    id: 'search',
    name: 'Search',
    type: 'search',
    fields: ['name', 'tags', 'category', 'address'],
  },
  {
    id: 'category',
    name: 'Category',
    type: 'list',
    options: [
      { label: 'All Categories', value: null },
      ...Object.entries(TAGS)
        .map(([k, v]) => ({
          label: v.label,
          value: k,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    ],
    fields: ['category'],
  },
  // {
  //   id: 'sort',
  //   name: 'Sort',
  //   type: 'list',
  //   options: SORT_OPTIONS,
  // },
];
