import ky from 'ky-universal';

export const apiClient = ky.extend({
  prefixUrl: process.env.APP_URL
    ? new URL('/api', process.env.APP_URL).href
    : '/api',
  headers: {
    'content-type': 'application/json',
  },
  credentials: 'same-origin',
  cache: 'no-cache',
  // throwHttpErrors: false,
});
