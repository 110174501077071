import kyUniversal from 'ky-universal';

const handleSignup = async (values: {
  email: string;
  firstName?: string;
  lastName?: string;
}) => {
  const { email, firstName, lastName } = values;
  const response = await kyUniversal('/api/newsletter', {
    method: 'POST',
    json: { email, firstName, lastName },
  });

  if ([200, 201].includes(response.status)) {
    return {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      body: await response.json(),
    };
  }
  throw new Error('Something went wrong');
};

export const useMailchimp = () => {
  return {
    handleSignup,
  };
};
