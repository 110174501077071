import { Options } from 'ky-universal';
import { Location } from 'lib/locations/models/location.model';
import { apiClient } from './client';

export interface QueryLocation {
  slug: string;
}

export interface FetchLocations {
  (options?: Pick<Options, 'searchParams'>): Promise<{ locations: Location[] }>;
}

export interface FetchLocation {
  (options: QueryLocation): Promise<{ location: Location }>;
}

export const fetchLocations: FetchLocations = async ({ searchParams } = {}) => {
  const locations = await apiClient.get('locations/', {
    searchParams,
  });
  return locations.json();
};

export const fetchLocation: FetchLocation = async (options) => {
  const location = await apiClient.get(`locations/${options.slug}`);
  return location.json();
};
