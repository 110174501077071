/* eslint-disable @typescript-eslint/no-misused-promises */
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { IsEmail } from 'class-validator';
import { clsxm } from 'lib';
import { useMailchimp } from 'lib/useMailchimp';
import * as React from 'react';
import { useForm } from 'react-hook-form';

enum NewsletterFormVariant {
  'primary',
  'outline',
  'ghost',
  'light',
  'dark',
}

interface NewsletterFormProps {
  buttonText?: string;
  headline?: string;
  slim?: boolean;
  subHeading?: string;
  variant?: keyof typeof NewsletterFormVariant;
}

class NewsletterFormValues {
  @IsEmail({}, { message: 'Invalid email' })
  email: string;
}

// interface NewsletterFormValues {
//   email: string;
// }

const NewsletterForm: React.FC<
  NewsletterFormProps &
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >
> = ({
  className,
  variant = 'ghost',
  slim = false,
  buttonText = 'Get Notified',
  headline,
  subHeading,
  ...rest
}) => {
  const { handleSignup } = useMailchimp();
  const form = useForm<NewsletterFormValues>({
    defaultValues: { email: '' },
    resolver: classValidatorResolver(NewsletterFormValues),
    reValidateMode: 'onChange',
    mode: 'onSubmit',
  });

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors, isSubmitting, isSubmitSuccessful, isSubmitted },
  } = form;

  const onSubmit = async (data: NewsletterFormValues) => {
    clearErrors();
    try {
      await handleSignup(data);
    } catch (error) {
      console.error(error);
      setError('email', { message: 'Something went wrong.', type: 'error' });
    }
  };

  const wrapperClasses = clsxm(
    // px-6 py-6 md:py-12 md:px-12 lg:py-16 lg:px-16
    'xl:flex xl:items-center',
    [
      variant === 'primary' && [
        'bg-green-700 text-white',
        'border-green-600 border',
      ],
      variant === 'outline' && ['text-green-700', 'border-green-700 border'],
      variant === 'ghost' && ['text-green-700', 'shadow-none'],
      variant === 'light' && [
        'text-stone-900 bg-white ',
        'border border-stone-300',
      ],
      variant === 'dark' && [
        'bg-stone-800 text-white',
        'border border-stone-600',
      ],
    ],
    className
  );

  return (
    <div className={wrapperClasses} {...rest}>
      <div className='motion-safe:transition-all xl:w-0 xl:flex-1'>
        {headline && (
          <h2
            className={clsxm(
              'text-center font-bold tracking-tight text-white motion-safe:transition-all sm:text-left ',
              isSubmitSuccessful && 'sm:text-center',
              slim ? 'text-lg sm:text-xl' : 'text-2xl sm:text-3xl'
            )}
          >
            {isSubmitSuccessful && "Done! We think you'll like it here."}
            {!isSubmitSuccessful && headline}
          </h2>
        )}
        {subHeading && (
          <p
            className={clsxm(
              'mt-3 max-w-3xl leading-6 text-green-100 motion-safe:transition-all',
              isSubmitSuccessful && 'block max-w-none sm:text-center',
              slim ? 'mt-1 text-sm' : 'mt-3 text-lg'
            )}
          >
            {isSubmitSuccessful &&
              'Be sure to check your email for a confirmation link.'}
            {!isSubmitSuccessful && subHeading}
          </p>
        )}
      </div>

      {!isSubmitSuccessful && (
        <div
          className={clsxm(
            'mt-8 sm:w-full sm:max-w-md xl:ml-8 xl:mt-0',
            slim && 'xl:ml-4'
          )}
        >
          <form
            className='sm:grid sm:grid-cols-6 sm:gap-2'
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className='col-span-4'>
              <label htmlFor='email' className='sr-only'>
                Email address
              </label>
              <input
                id='email'
                type='email'
                autoComplete='email'
                // className='w-full rounded-md border-white px-4 py-1.5 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-green-700'
                className={clsxm(
                  'w-full appearance-none motion-safe:transition-all',
                  'rounded-md border border-stone-300 p-3 placeholder-stone-400',
                  'focus:border-teal-400 focus:outline-none focus:ring-teal-400 ',
                  'text-stone-700',
                  errors.email &&
                    'border-red-400 focus:border-red-500 focus:ring-red-500',
                  errors.email && 'pb-4 pt-2',
                  slim && 'p-1.5 text-xs',
                  errors.email && slim && 'pb-3 pt-1'
                )}
                aria-invalid={!!errors.email}
                placeholder='Enter your email'
                {...register('email')}
              />
              <div className='relative w-full'>
                <span
                  className={clsxm(
                    'absolute text-xs text-red-600 opacity-0 motion-safe:transition-all',
                    errors.email && 'opacity-100',
                    slim ? '-mt-4 px-1.5' : '-mt-4 px-3'
                  )}
                >
                  {errors.email?.message}
                </span>
              </div>
            </div>
            <div className='col-span-2'>
              <button
                type='submit'
                className={clsxm(
                  'mt-3 flex h-full w-full min-w-full items-center justify-center rounded-md border border-transparent bg-green-500 px-3 py-2 font-bold text-white shadow-sm hover:bg-green-400 focus:outline-none focus:ring-2 focus:ring-green-200 focus:ring-offset-2 sm:mt-0 sm:w-fit sm:flex-shrink-0 sm:text-sm',
                  {
                    'cursor-not-allowed': isSubmitting,
                    'animate-pulse': isSubmitting,
                  },
                  slim && 'px-2 py-1 text-xs sm:text-xs'
                )}
                disabled={isSubmitting}
              >
                {isSubmitting && '...'}
                {!isSubmitting && buttonText}
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export { NewsletterForm };
