import * as React from 'react';
import { QueryFunction, useQuery } from 'react-query';
import { fetchLocations as fetchLocationsQuery } from 'lib/api';
import { SearchParamsOption } from 'ky-universal';
import { isEmpty, omit } from 'lodash';
import { debouncify } from 'utils-decorators';
import { hoursToMilliseconds } from 'date-fns';
import { Location } from './locations';

export interface ListFilterOption {
  disabled?: boolean;
  label: string;
  value: string | null;
}
export type Filter<TData> =
  | {
      fields?: string[];
      id: string;
      name: string;
      options: ListFilterOption[];
      type: 'list';
    }
  | { fields?: (keyof TData)[]; id: string; name: string; type: 'search' };

export type QuerySearchFilter = {
  id: string;
  value: string;
};

export type QueryListFilter = {
  id: string;
  value: string | null;
};

export type QueryItem = QuerySearchFilter | QueryListFilter;

export type Query = { [key: string]: QueryItem };

export const fetchLocations: QueryFunction<
  Location[],
  (string | Query | undefined)[]
> = async (params) => {
  const searchParams = Object.entries(params.queryKey[1] as Query).reduce(
    (acc, curr) => {
      const { id, value } = curr[1];
      if (isEmpty(value)) {
        return acc;
      }

      const newItem = { [id]: value };
      return { ...acc, ...newItem };
    },
    {}
  ) as SearchParamsOption;
  const res = (await fetchLocationsQuery({ searchParams })).locations;
  return res;
};

export type ReducerAction =
  | { payload: QueryItem; type: 'SET_VALUE' }
  | { payload: QueryItem[]; type: 'SET_VALUES' }
  | { payload: { id: string }; type: 'CLEAR_VALUE' };

const queryReducer = (state: Query, action: ReducerAction): Query => {
  switch (action.type) {
    case 'SET_VALUE':
      return {
        ...state,
        ...{ [action.payload.id]: action.payload },
      };
    case 'CLEAR_VALUE':
      // the value from the state where the key is the payload ID
      return omit(state, action.payload.id);
    default:
      return state;
  }
};

export const defaultQuery: Query = {
  category: { id: 'category', value: null },
  sort: { id: 'sort', value: 'name' },
};

export const useSearch = <TData = unknown>({
  initialQuery = defaultQuery,
  queryOptions = {},
}: {
  data?: TData[];
  filters?: Filter<TData>[];
  initialQuery?: Query;
  queryOptions?: any;
}) => {
  const [query, reducer] = React.useReducer(queryReducer, initialQuery);
  const queryResponse = useQuery(['locations', query], fetchLocations, {
    staleTime: hoursToMilliseconds(1),
  });

  const setQueryValue = (input: QueryItem) => {
    reducer({ type: 'SET_VALUE', payload: input });
  };

  const debouncedSetQueryValue = React.useMemo(
    () => debouncify(setQueryValue, 500),
    []
  );

  return {
    query,
    setQueryValue: debouncedSetQueryValue,
    queryResponse,
    data: queryResponse.data,
    status: queryResponse.status,
  };
};
