import * as React from 'react';
import { clsxm } from '../../lib/clsxm';

interface TitleProps {
  children?: any;
  className?: string;
}
const Title: React.FC<TitleProps> = ({ children, className, ...rest }) => {
  return (
    <h2
      className={clsxm(
        'text-center text-3xl font-bold text-teal-700 md:text-4xl',
        className
      )}
      {...rest}
    >
      {children}
    </h2>
  );
};

export { Title };
