import * as React from 'react';
import { InferGetStaticPropsType } from 'next';
import { dehydrate, QueryClient } from 'react-query';
import { Locations } from 'components/Locations';
import { Sponsors } from 'components/Sponsors';
import { WhatIsWFTD } from 'components/WhatIsWFTD';
import { defaultQuery } from 'lib/useSearch';
import { Layout } from 'components/layout/Layout';
import { SEO } from 'components/SEO';
import { LocationsService } from 'lib/locations/locations.service';
import Container from 'typedi';
import { instanceToPlain } from 'class-transformer';

import 'reflect-metadata';
import { NewsletterSection } from 'components/Newsletter/NewsletterSection';
import { EventJsonLdProps, EventJsonLd } from 'next-seo';
import { Hero } from 'components/Hero';

const eventLDProps: EventJsonLdProps = {
  name: 'Chicago Fair Trade Open House',
  startDate: '2023-05-13',
  endDate: '2023-05-13',
  location: {
    name: 'Chicago',
    address: {
      addressLocality: 'Chicago',
      addressRegion: 'IL',
      addressCountry: 'US',
    },
  },
  url: 'https://openhouse.chicagofairtrade.org/',
  description: 'The biggest celebration of World Fair Trade Day in the USA!',
  organizer: {
    type: 'Organization',
    name: 'Chicago Fair Trade',
    url: 'https://www.chicagofairtrade.org',
  },
  images: [
    '/static/social-image-banner@2x.png',
    '/static/Open-House-Coffee-Morning.jpg',
  ],
};

const HomePage: React.FC<
  InferGetStaticPropsType<typeof getStaticProps>
> = () => {
  return (
    <Layout>
      <SEO />
      <EventJsonLd {...eventLDProps} />
      <main>
        <Hero />
        <div className='mt-12 space-y-12 sm:space-y-24'>
          <Locations />
          <WhatIsWFTD />
          <NewsletterSection />
          <Sponsors />
        </div>
      </main>
    </Layout>
  );
};

export const getStaticProps = async () => {
  const queryClient = new QueryClient();
  const locationsSvc = Container.get(LocationsService);
  const locations = await locationsSvc.allLocations({
    where: {
      status: {
        equals: 'PUBLISHED',
      },
    },
    orderBy: [
      {
        order: 'asc',
      },
      {
        name: 'asc',
      },
    ],
  });

  queryClient.setQueryData(
    ['locations', defaultQuery],
    instanceToPlain(locations)
  );

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
    },
  };
};

export default HomePage;
