import * as React from 'react';
import { useFeatureFlags } from 'lib/useFlags';
import Image from 'next/image';
import WFTDStacked from 'assets/images/WFTD Card Stack@2x.png';

interface HeroProps {}

const Hero: React.FC<HeroProps> = () => {
  const {
    flags: { EVENT_IS_ENDED },
  } = useFeatureFlags();

  return (
    <section className='map-bg relative overflow-hidden'>
      <div className='mx-auto grid max-w-7xl items-center gap-12 px-4 py-8 text-center sm:px-6 md:grid-cols-2 md:justify-center md:py-16 md:text-left'>
        <div className='w-full'>
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
          <Image src={WFTDStacked} alt='' priority placeholder='empty' />
        </div>
        <div className='w-full space-y-4'>
          <h1 className='text-4xl font-bold tracking-tight text-white md:text-5xl xl:text-6xl'>
            Open House Chicago&nbsp;Fair&nbsp;Trade
          </h1>
          <p className='text-3xl font-bold tracking-tight text-green-300 md:text-3xl xl:text-4xl'>
            World Fair Trade Day Celebration
          </p>
          <p className='text-3xl font-bold tracking-tight text-green-300 md:text-3xl xl:text-4xl'>
            Saturday, May 13<sup>th</sup>
          </p>
          {EVENT_IS_ENDED && (
            <p className='!mt-8 text-xl text-chocolate-300'>
              <strong className='uppercase tracking-wider'>
                Thanks for joining us!
              </strong>
              <br />
              We look forward to seeing you at next year's event!
            </p>
          )}
        </div>
      </div>
    </section>
  );
};

export { Hero };
