import { ButtonLink } from 'components/links';
import { Title } from 'components/Title';
import * as React from 'react';

interface WhatIsWFTDProps {}

const WhatIsWFTD: React.FC<WhatIsWFTDProps> = () => {
  return (
    <section className='bg-teal-50 px-4 py-12'>
      <div className='container mx-auto max-w-5xl text-xl text-teal-500'>
        <Title className='mb-6'>What is World Fair Trade Day?</Title>

        <p>
          Each May, World Fair Trade Day is celebrated by tens of thousands of
          people in dozens of countries. First held in 1989, World Fair trade
          Day has grown to include individual advocates, businesses, nonprofits,
          faith based organizations and educational institutions.
          <br />
          For sixteen years, Chicago Fair Trade has organized the largest World
          Fair Trade Day in the U.S. On this day, we celebrate the health of the
          planet, and the labor, dignity, and equality of all people.
        </p>
        <p className='mt-6 text-center'>
          <ButtonLink href='/about' variant='teal'>
            Learn More
          </ButtonLink>
        </p>
      </div>
    </section>
  );
};

export { WhatIsWFTD };
